@use "../../../../../tokens";

.hero {
  background: linear-gradient(270deg, #9948fc 6.7%, #4b179f 67.9%);
  overflow: hidden;
  position: relative;

  @media screen and (min-width: tokens.$screen-sm) {
    border-radius: tokens.$border-radius-xl;
  }
}

.heroContent {
  align-items: center;
  color: tokens.$color-white;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: tokens.$layout-md;
  padding: tokens.$layout-xs;
  z-index: 1;

  @media screen and (min-width: tokens.$screen-sm) {
    padding: tokens.$layout-sm;
  }
}

.heroBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  z-index: 0;
}

.heroTitle {
  align-items: center;
  display: flex;
  gap: tokens.$spacing-md;
  flex-direction: column;
  max-width: tokens.$content-md;
  text-align: center;

  @media screen and (min-width: tokens.$screen-md) {
    padding: 0 tokens.$layout-md;
  }

  h2 {
    font: tokens.$text-title-sm;
    font-family: var(--font-inter);
    font-weight: 400;
    line-height: 1.25em;
  }

  p {
    font: tokens.$text-body-lg;
  }
}

.heroCta {
  width: 100%;

  form {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-md;

    label {
      color: tokens.$color-purple-05;
    }
  }

  @media screen and (min-width: tokens.$screen-md) {
    max-width: tokens.$content-md;
    padding: 0 tokens.$layout-md;

    form {
      align-items: flex-end;
      flex-direction: row;
      button {
        flex: 0 0 auto;
      }
    }
  }

  // Override the background color in oder to increase
  // the contrast against the background.
  form > button {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: tokens.$color-purple-90 !important;
    &:hover {
      /* stylelint-disable-next-line declaration-no-important */
      background-color: tokens.$color-purple-80 !important;
    }
  }
}

.heroBanner {
  background: rgba(tokens.$color-white, 0.05);
  border-radius: tokens.$border-radius-xl;
  display: flex;
  justify-content: center;
  padding: tokens.$spacing-md;
  width: 100%;

  @media screen and (min-width: tokens.$screen-md) {
    padding: tokens.$spacing-xl;
  }

  &List {
    display: flex;
    gap: tokens.$spacing-lg;
    flex-direction: column;
    padding: 0;
    max-width: tokens.$content-xl;

    @media screen and (min-width: tokens.$screen-md) {
      flex-direction: row;
    }

    @media screen and (min-width: tokens.$screen-md) {
      gap: tokens.$spacing-xl;
    }
  }

  &Item {
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-md;
    position: relative;
    padding-left: tokens.$layout-md;

    strong {
      font: tokens.$text-body-lg;

      @media screen and (min-width: tokens.$screen-sm) {
        font: tokens.$text-body-xl;
      }
    }

    @media screen and (max-width: calc(tokens.$screen-lg - 1px)) {
      // Add a bit more space: The next spacing step is too much.
      padding-left: calc(tokens.$layout-sm + tokens.$spacing-sm);

      span {
        @include tokens.visually-hidden;
      }
    }

    svg {
      fill: tokens.$color-purple-30;
      padding: tokens.$spacing-xs;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-10%);
      height: 32px;
      width: 32px;

      @media screen and (min-width: tokens.$screen-lg) {
        height: 40px;
        width: 40px;
      }
    }
  }
}
