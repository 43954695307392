@use "../../../../../tokens";

.infoBlock {
  align-items: center;
  background-color: tokens.$color-grey-05;
  border-radius: tokens.$border-radius-xl;
  display: flex;
  flex-direction: column;
  gap: tokens.$layout-sm;
  width: 100%;

  @media screen and (min-width: tokens.$screen-md) {
    padding: tokens.$layout-sm;
  }
}

.infoHero {
  align-items: center;
  display: flex;
  max-height: 60vw;
  max-width: tokens.$content-sm;
  width: 100%;

  @media screen and (min-width: tokens.$screen-md) {
    display: none;
  }
}

.infoImageWrapper {
  display: none;
  flex: 1 1 5%;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: tokens.$screen-md) {
    display: block;
  }

  @media screen and (min-width: tokens.$screen-lg) {
    flex: 1 1 10%;
  }

  img {
    display: block;
    height: auto;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    padding: 0 10%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.infoRow {
  display: flex;
  gap: tokens.$layout-xs;
  max-width: tokens.$content-lg;
  width: 100%;

  &:nth-child(odd) {
    @media screen and (min-width: tokens.$screen-md) {
      flex-direction: row-reverse;
    }

    .infoImageWrapper {
      flex: 1 1 10%;

      img {
        padding: 0;
      }

      @media screen and (min-width: tokens.$screen-lg) {
        flex: 1 1 20%;
      }
    }
  }
}

.infoText {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: tokens.$spacing-md;

  @media screen and (min-width: tokens.$screen-md) {
    padding: tokens.$layout-sm 0;
  }
}

.infoLabel {
  background-color: tokens.$color-grey-10;
  border-radius: tokens.$border-radius-lg;
  // Add a bit more space: The next spacing step is too much.
  padding: tokens.$spacing-xs calc(tokens.$spacing-md + tokens.$spacing-xs);
}
