@use "../../../../../tokens";

.content {
  align-items: center;
  border-radius: tokens.$border-radius-lg;
  box-shadow: inset 0 0 0 6px tokens.$color-grey-10;
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-md;
  max-width: tokens.$content-lg;
  padding: tokens.$layout-xs;
  width: 100%;

  @media screen and (min-width: tokens.$screen-sm) {
    gap: tokens.$spacing-lg;
    padding: tokens.$layout-sm;
  }

  @media screen and (min-width: tokens.$screen-md) {
    gap: tokens.$spacing-xl;
    padding: tokens.$layout-md;
  }
}

.faqList {
  display: flex;
  flex-direction: column;
  width: 100%;

  dd {
    background: tokens.$color-white;
    border-radius: 0 0 tokens.$border-radius-lg tokens.$border-radius-lg;
    border: 2px solid tokens.$color-grey-10;
    border-top: none;
  }

  dt {
    border-radius: tokens.$border-radius-lg;
    border: 2px solid tokens.$color-grey-10;
    margin-top: tokens.$spacing-md;

    &:focus-within {
      border-color: tokens.$color-purple-70;

      & + dd {
        border-color: tokens.$color-purple-70;
      }
    }

    &:has([aria-expanded="true"]) {
      border-radius: tokens.$border-radius-lg tokens.$border-radius-lg 0 0;
      border-bottom: none;
    }
  }

  .faqQuestion {
    align-items: center;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font: tokens.$text-body-lg;
    font-weight: 600;
    gap: tokens.$layout-xs;
    justify-content: space-between;
    padding: tokens.$spacing-md;
    text-align: start;
    width: 100%;

    @media screen and (min-width: tokens.$screen-md) {
      gap: tokens.$layout-md;
      padding: tokens.$spacing-lg;
    }

    svg {
      color: tokens.$color-black;
      flex: 1 0 16px;
      max-width: 16px;
    }

    &:focus {
      outline: none;
    }
  }

  .faqAnswer {
    display: none;
    height: 0;
    padding: tokens.$spacing-sm tokens.$layout-xs tokens.$spacing-md
      tokens.$spacing-md;

    @media screen and (min-width: tokens.$screen-md) {
      padding: tokens.$spacing-sm tokens.$layout-lg tokens.$spacing-lg
        tokens.$spacing-lg;
    }

    &.expanded {
      display: block;
      height: auto;
    }
  }
}
