.preserveWhitespace {
  white-space: pre;
}

.preventTextSelection {
  user-select: none;
}

.chartLabel {
  white-space: pre;
  width: 100%;
  text-anchor: middle;
}
