@use "../../../../../tokens";

.banner {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: tokens.$layout-sm;
  max-width: tokens.$content-lg;
  text-align: center;

  @media screen and (min-width: tokens.$screen-sm) {
    padding: 0 tokens.$layout-xs;
  }

  &Header {
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-sm;

    b,
    strong {
      font-weight: 700;
    }

    strong {
      color: tokens.$color-purple-70;
    }
  }
}
